import { useIntl } from 'react-intl';

import { AppRoutesProps } from '@bootstrap/configs/route-access';
import { FEATURE_FLAGS } from '@debtor-portal/configs/featureFlags';
import { ReactComponent as DocumentScanner } from '@ui/assets/icons/document-scanner.svg';
import { ReactComponent as Group } from '@ui/assets/icons/group.svg';
import { ReactComponent as Home } from '@ui/assets/icons/home.svg';
import { ReactComponent as NoteStack } from '@ui/assets/icons/note_stack.svg';
import { ReactComponent as Payments } from '@ui/assets/icons/payments.svg';

export const useRoutes = (): AppRoutesProps[] => {
  const { formatMessage } = useIntl();
  return [
    {
      path: '/',
      title: formatMessage({ id: 'field.dashboard' }),
      icon: <Home />,
    },

    {
      path: '/invoicing',
      title: formatMessage({ id: 'field.invoicing' }),
      subRoutes: [
        {
          path: '/invoices',
          title: formatMessage({ id: 'field.invoices' }),
          icon: <DocumentScanner />,
        },
        {
          path: '/collective-invoices',
          title: formatMessage({ id: 'field.collectiveInvoices' }),
          icon: <NoteStack />,
        },
        {
          path: '/billing-requests',
          title: formatMessage({ id: 'field.billingRequests' }),
          icon: <Payments />,
          subRoutes: [
            {
              path: '/billing-requests/:id',
              title: formatMessage({ id: 'field.billingRequests' }),
              hidden: true,
            },
          ],
        },
      ],
    },
    {
      path: '/relations',
      title: formatMessage({ id: 'field.relations' }),
      hidden: !FEATURE_FLAGS.SUPPLIERS_PAGE,
      subRoutes: [
        {
          path: '/suppliers',
          title: formatMessage({ id: 'field.suppliers' }),
          icon: <Group />,
          hidden: !FEATURE_FLAGS.SUPPLIERS_PAGE,
          subRoutes: [
            {
              path: '/suppliers/:id',
              title: formatMessage({ id: 'field.suppliers' }),
              hidden: true,
            },
            {
              path: '/suppliers/:id/overview',
              title: formatMessage({ id: 'field.suppliers' }),
              hidden: true,
            },
            {
              path: '/suppliers/:id/contracts',
              title: formatMessage({ id: 'field.suppliers' }),
              hidden: true,
            },
            {
              path: '/suppliers/:id/invoices',
              title: formatMessage({ id: 'field.suppliers' }),
              hidden: true,
            },
          ],
        },
      ],
    },

    {
      path: '/profile',
      title: formatMessage({ id: 'field.profile' }),
      hidden: true,
    },
    {
      path: '/direct-debit',
      title: formatMessage({ id: 'field.directDebit' }),
      hidden: true,
    },
    {
      path: '/logout',
      title: formatMessage({ id: 'action.logout' }),
      hidden: true,
    },
  ];
};

import { forwardRef, useEffect } from 'react';

import {
  ColorNegative700,
  ColorNeutral600,
  ColorPositive700,
  ColorPrimary700,
  ColorPrimary500,
  ColorNeutral400,
  ColorWarning700,
} from '@hub/design-tokens/colors';
import { Flex } from '@ui/flex';
import { Spinner } from '@ui/spinner';
import { Typography, TypographyType } from '@ui/typography';

import { ButtonStyles } from './Button.styles';
import { IButtonProps } from './Button.types';

const colors = {
  default: ColorPrimary700,
  positive: ColorPositive700,
  negative: ColorNegative700,
  warning: ColorWarning700,
  secondary: ColorPrimary500,
} as const;

const typographyType = {
  small: (_variant: IButtonProps['variant']) => TypographyType.BODY_S,
  default: (variant: IButtonProps['variant']) =>
    variant === 'secondary' ? TypographyType.BODY_M_REGULAR : TypographyType.BODY_M_BOLD,
  large: (variant: IButtonProps['variant']) =>
    variant === 'secondary' ? TypographyType.BODY_L_REGULAR : TypographyType.BODY_L_BOLD,
};

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      children,
      onClick,
      onMouseOver,
      onMouseOut,
      onMouseDown,
      type = 'button',
      variant = 'default',
      disabled = false,
      isLoading = false,
      iconLeft,
      iconRight,
      name,
      size = 'default',
      fullWidth,
      isActive,
      ...ariaProps
    },
    ref,
  ) => {
    useEffect(() => {
      if (!children && !ariaProps['aria-label'] && !disabled) {
        console.error('Please add aria-label for screen users: https://www.w3.org/TR/WCAG20-TECHS/ARIA14.html');
      }
    }, [ariaProps, children, disabled]);

    return (
      <ButtonStyles
        type={type}
        variant={variant}
        disabled={disabled}
        isLoading={isLoading}
        onClick={isLoading || disabled ? undefined : onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onMouseDown={onMouseDown}
        iconOnly={!children}
        name={name}
        ref={ref}
        size={size}
        fullWidth={!!fullWidth}
        isActive={!!isActive}
        {...ariaProps}
      >
        <Flex gap={size === 'small' ? 4 : 8} justify="center" align="center">
          {isLoading ? <Spinner color={colors[variant]} /> : iconLeft}
          {children ? (
            <Typography
              type={typographyType[size](variant)}
              color={disabled ? (variant === 'secondary' ? ColorNeutral400 : ColorNeutral600) : colors[variant]}
              as="span"
            >
              {children}
            </Typography>
          ) : null}
          {iconRight}
        </Flex>
      </ButtonStyles>
    );
  },
);

Button.displayName = 'Button';

import { ColorNegative500, ColorNeutral400, ColorNeutral800 } from '@hub/design-tokens';
import { Typography, TypographyType } from '@ui/typography';

import { ContextMenuItemWrapper } from './ContextMenu.styles';

interface IContextMenuItemProps {
  label: string | JSX.Element;
  onClick: () => void;
  iconLeft: JSX.Element;
  iconRight?: JSX.Element;
  variant?: 'default' | 'negative';
  isDisabled?: boolean;
}

export const ContextMenuItem = ({
  label,
  onClick,
  iconLeft,
  iconRight,
  variant = 'default',
  isDisabled = false,
}: IContextMenuItemProps) => {
  const isNegative = variant === 'negative';
  return (
    <ContextMenuItemWrapper isNegative={isNegative} onClick={onClick} disabled={isDisabled}>
      {iconLeft}
      <Typography
        type={TypographyType.BODY_M_REGULAR}
        color={isNegative ? ColorNegative500 : isDisabled ? ColorNeutral400 : ColorNeutral800}
      >
        {label}
      </Typography>
      {iconRight}
    </ContextMenuItemWrapper>
  );
};

import styled, { css } from 'styled-components';

import { TypographyStyles } from '@ui/typography/Typography.styles';

import { IButtonProps } from './Button.types';

const backgrounds = {
  default: 'var(--color-primary-300)',
  positive: 'var(--color-positive-300)',
  negative: 'var(--color-negative-300)',
  warning: 'var(--color-warning-300)',
  secondary: 'transparent',
};

const hoverBackgrounds = {
  default: 'var(--color-primary-400)',
  positive: 'var(--color-positive-400)',
  negative: 'var(--color-negative-400)',
  warning: 'var(--color-warning-400)',
  secondary: 'var(--color-neutral-100)',
};

const activeBackgrounds = {
  default: 'var(--color-primary-500)',
  positive: 'var(--color-positive-500)',
  negative: 'var(--color-negative-500)',
  warning: 'var(--color-warning-500)',
  secondary: 'var(--color-neutral-200)',
};

const colors = {
  default: 'var(--color-primary-700)',
  positive: 'var(--color-positive-700)',
  negative: 'var(--color-negative-700)',
  warning: 'var(--color-warning-700)',
  secondary: 'var(--color-primary-500)',
};

const svgSizes = {
  default: '24px',
  small: '20px',
  large: '32px',
};

const paddings = {
  default: (iconOnly: boolean) => (iconOnly ? '8px' : '8px 12px'),
  small: (iconOnly: boolean) => (iconOnly ? '4px' : '4px 8px'),
  large: (iconOnly: boolean) => (iconOnly ? '8px' : '8px 12px'),
};

type ButtonProps = Required<
  Pick<IButtonProps, 'variant' | 'disabled' | 'isLoading' | 'size' | 'fullWidth' | 'isActive'> & { iconOnly: boolean }
>;

export const ButtonStyles = styled.button<ButtonProps>`
  min-width: ${({ iconOnly }) => !iconOnly && '80px'};
  ${({ fullWidth }) => fullWidth && `width: 100%`};
  padding: ${({ iconOnly, size }) => paddings[size](iconOnly)};
  color: ${({ variant }) => colors[variant]};
  background-color: ${({ variant, isLoading }) => (isLoading ? activeBackgrounds[variant] : backgrounds[variant])};
  border-radius: var(--border-radius-default);
  border: none;
  box-shadow: inset 0 0 0 1px transparent;
  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};

  svg {
    flex-shrink: 0;
    width: ${({ size }) => svgSizes[size]};
    height: ${({ size }) => svgSizes[size]};
  }

  &:focus-visible {
    outline: auto;
    outline-offset: -1px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover:not(:disabled) {
      background-color: ${({ variant, isLoading }) => !isLoading && hoverBackgrounds[variant]};
    }
  }

  &:active:not(:disabled) {
    background-color: ${({ variant, isLoading }) => !isLoading && activeBackgrounds[variant]};
  }

  ${({ variant, isLoading }) => {
    if (variant === 'secondary') {
      return css`
        &:disabled {
          background-color: transparent;
          color: var(--color-neutral-400);
          cursor: default;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover:not(:disabled) {
            box-shadow: ${!isLoading && 'inset 0 0 0 1px var(--color-neutral-300)'};
          }
        }

        &:active:not(:disabled) {
          box-shadow: ${!isLoading && 'inset 0 0 0 1px var(--color-neutral-300)'};
        }
      `;
    }
    return css`
      &:disabled {
        background-color: var(--color-neutral-300);
        color: var(--color-neutral-600);
        cursor: default;
      }
    `;
  }}

  ${({ theme, variant }) => {
    if (!theme?.ctaBackgroundColor) return;
    if (variant === 'secondary') {
      return css`
        &:not(:disabled) {
          color: ${theme.ctaBackgroundColor};
          ${TypographyStyles} {
            color: ${theme.ctaBackgroundColor};
          }
        }
      `;
    }
    return css`
      &:not(:disabled) {
        color: ${theme.ctaTextColor};
        background-color: ${theme.ctaBackgroundColor};
        ${TypographyStyles} {
          color: ${theme.ctaTextColor};
        }

        &:active {
          background-color: ${theme.ctaBackgroundColorActive};

          ${TypographyStyles} {
            color: ${theme.ctaTextColor};
          }
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: ${theme.ctaBackgroundColorHover};

            ${TypographyStyles} {
              color: ${theme.ctaTextColor};
            }
          }
        }
      }
    `;
  }}

  ${({ isActive, variant }) =>
    isActive &&
    css`
      background-color: ${activeBackgrounds[variant]};

      @media (hover: hover) and (pointer: fine) {
        &:hover:not(:disabled) {
          background-color: ${activeBackgrounds[variant]};
        }
      }
    `}
`;

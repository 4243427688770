import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMobile } from '@bootstrap/hooks/useMedia';
import { ColorNeutral800, ShadowDefault } from '@hub/design-tokens';
import { ReactComponent as Close } from '@ui/assets/icons/close.svg';
import { Button } from '@ui/button';
import { Flex } from '@ui/flex';
import { Paper } from '@ui/paper';
import { Typography, TypographyType } from '@ui/typography';

export const HIDE_BANNER = 'hideBanner';

interface IBannerProps {
  title: string | JSX.Element;
  onClick: () => void;
  description?: string | JSX.Element;
}

export const Banner = ({ title, description, onClick }: IBannerProps) => {
  const isMobile = useMobile();
  const { formatMessage } = useIntl();
  const [isHidden, setIsHidden] = useState(() => localStorage.getItem(HIDE_BANNER) === 'true');

  useEffect(() => {
    const interval = setInterval(() => {
      const hideBanner = localStorage.getItem(HIDE_BANNER) === 'true';
      setIsHidden(hideBanner);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  if (isHidden) return null;

  return (
    <Paper offset={isMobile ? 16 : 24} boxShadow={ShadowDefault}>
      <Flex gap={16} justify="space-between" align="center" offsetBottom={isMobile ? 16 : 24}>
        <Typography type={TypographyType.HEADING_S} color={ColorNeutral800}>
          {title}
        </Typography>
        <Button
          variant="secondary"
          iconLeft={<Close aria-hidden />}
          onClick={() => localStorage.setItem(HIDE_BANNER, 'true')}
          aria-label={formatMessage({ id: 'action.close' })}
        />
      </Flex>
      {description && (
        <Typography type={TypographyType.BODY_M_REGULAR} offsetBottom={isMobile ? 16 : 24}>
          {description}
        </Typography>
      )}
      <Button onClick={onClick} fullWidth={isMobile}>
        <FormattedMessage id="action.contactFinqle" />
      </Button>
    </Paper>
  );
};

import * as Sentry from '@sentry/react';
import { version as reactVersion, Component, ReactNode, ErrorInfo } from 'react';
import { version as reactDomVersion } from 'react-dom';
import { injectIntl } from 'react-intl';

import { EnvironmentsEnums } from '@bootstrap/constants/environments';
import deniedImg from '@ui/assets/img/denied.svg';
import { Notice } from '@ui/notice';

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  hasError: boolean;
};

class ErrorBoundaryWithoutIntl extends Component<{ children?: ReactNode; intl: any }, ErrorBoundaryState> {
  override state: ErrorBoundaryState = {
    error: null,
    errorInfo: null,
    hasError: false,
  };

  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
      hasError: true,
    });
  }

  override render(): ReactNode {
    const { intl } = this.props;

    const errorTitle = intl.formatMessage({
      id: 'errorBoundary.errorCaptured',
      defaultMessage: 'Error captured',
    });

    const errorDescription = (
      <>
        {intl.formatMessage({
          id: 'errorBoundary.moduleError',
          defaultMessage: 'This module produced an error and had to terminate unexpectedly..',
        })}
        <br />
        {intl.formatMessage({
          id: 'errorBoundary.contactUsIfProblemPersists',
          defaultMessage: 'If this problem persists, contact us.',
        })}
        {process.env.NODE_ENV === EnvironmentsEnums.DEVELOPMENT && this.state.errorInfo && (
          <details style={{ whiteSpace: 'pre-wrap', textAlign: 'left', marginTop: '2em' }}>
            <summary>
              ReactJS: {reactVersion}, ReactDOM: {reactDomVersion}
              <br />
              {this.state.error?.toString()}
            </summary>
            {this.state.errorInfo?.componentStack}
          </details>
        )}
      </>
    );

    return (
      <Sentry.ErrorBoundary
        fallback={() => <Notice title={errorTitle} description={errorDescription} image={deniedImg} />}
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export const ErrorBoundary = injectIntl(ErrorBoundaryWithoutIntl);
